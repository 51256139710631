body {
    background-color: rgb(20, 20, 20);
}

.hero-container {
    top: 33%;
    left: 150px;
    position: absolute;
}

.hero-text {
    color: white;
    float:left;
    font-size: min(100px, 12vw);
    font-family: "Source Code Pro", monospace;
}

.hero-img{
    z-index: 10;
}

.hero-job {
    float: left;
    width: 50vw;
    color:rgb(0, 247, 255);
    text-shadow: 3px -3px rgb(0, 153, 255), 6px -6px rgb(0, 38, 255);
    user-select: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    font-family: "Source Code Pro", monospace;
} 