* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-family: "Source Code Pro", monospace;
  }
  
  .home,
  .projects,
  .experience,
  .contactMe {
    display: flex;
    height: 90vh;
    align-items: center;
    justify-content: center;
    font-size: 3rem;
  }