h1 {
    margin-bottom: 10%;
    color: white;
    text-decoration: none;
    text-transform: uppercase;
}

.sidebar {
    background-color: rgb(15, 15, 15);
    display: flex;
    top: 0;
    height: 100%;
    min-width: 140px;
    object-fit: cover;
    position: fixed;
    z-index: 999;
}

.sidebar-logo {
    justify-self: start;
    background-color: rgb(13, 13, 13);
    cursor: pointer;
    align-items: center;
    text-align: center;
}

.logo-container {
    background-color: rgb(10, 10, 10);
    position: absolute;
    height: auto;
    width: 100%;
}

.logo-img {
    padding-left: 15%;
    padding-top: 15%;
    padding-right: 15%;
    width: 100%;
    height: auto;
    cursor: pointer;
}

.logo-name {
    background-color: black;
    background-image: linear-gradient(90deg, rgb(0, 98, 255), rgb(0, 233, 255) 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -moz-background-clip: text;
    -webkit-text-fill-color: transparent;
    -moz-text-fill-color: transparent;
}

.logo-job {
    font-weight: bold;
    color: white;
    text-decoration: none;
    font-family: "Amatic SC";
    font-size: 1.5rem;
}

.sidebar-socials {
    display: flex;
    justify-content: space-between;
    position: absolute;
    padding-left: 10px;
    padding-right: 10px;
    top: 200px;
    width: 100%;
    font-size: 2rem;
    color: rgb(190, 190, 190);
    list-style-type: none;
}

.social-links {
    color: rgb(190, 190, 190);
}

.social-links:hover {
    color: rgb(0, 162, 255);
    transition: all 0.3s ease-in-out;
}

.sidebar-list {
    display: block;
    line-height: 3rem;
    position: absolute;
    top: max(50%, 250px);
    width: 100%;
    align-items: center;
}

.sidebar-list>li:first-child {
    border-top: 1px solid black;
}

.sidebar-row {
    background: linear-gradient(to right, rgb(0, 98, 255) 0%, rgb(0, 233, 255) 50%, transparent 50%, transparent 100%);
    background-size: 200%, 100%;
    background-position: 100%, 0;
    display: flex;
    cursor: pointer;
    color: rgb(190, 190, 190);
    border-bottom: 1px solid black;
    width: 100%;
    font-size: 1rem;
    padding-left: 6px;
    transition: all 0.3s ease-out;
}

.sidebar-row-text {
    flex: 1;
    text-align: right;
    padding-right: 6px;
}

.sidebar-footer {
    position: absolute;
    bottom: 0;
    width: 100%;
    text-align: center;
    background-color: rgb(10, 10, 10);
}

.sidebar-row:hover {
    background-position: 0 0;
    color:rgb(15,15,15);
}

a,
a:link,
a:visited,
a:hover,
a:active {
    text-decoration: none;
}

@media screen and (max-width: 960px) {
    .sidebar {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0%;
        left: -300%;
        opacity: 1;
        transition: all 0.5s ease;
    }

    .sidebar.active{
        background: rgb(15, 15, 15);
        left: 0;
        opacity: 1;
        transition: all 0.5s ease;
        z-index: 1;
    }

    .sidebar-socials {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 90vh;
        position: absolute;
        top: 50px;
        left: -300%;
        opacity: 1;
        transition: all 0.5s ease;
    }

    .sidebar-socials.active{
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 90vh;
        position: absolute;
        top: 100px;
        left: -300%;
        opacity: 1;
        transition: all 0.5s ease;
    }

    .sidebar-list.active {
        background: rgb(15, 15, 15);
        left: 0;
        opacity: 1;
        transition: all 0.5s ease;
        z-index: 1;
    }

    .sidebar-list {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100vh;
        position: absolute;
        top: 40%;
        left: -300%;
        opacity: 1;
        transition: all 0.5s ease;
    }

    .sidebar-footer {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 90vh;
        position: absolute;
        top: 80px;
        left: -300%;
        opacity: 1;
        transition: all 0.5s ease;
    }

    .sidebar-logo {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 90vh;
        position: absolute;
        top: 50px;
        left: -300%;
        opacity: 1;
        transition: all 0.5s ease;
    }

    /* .sidebar-logo.active{
        left: 0;
        opacity: 1;
        transition: all 0.5s ease;
        z-index: 4;
        justify-self: start;
    } */

    .menu-icon {
        background-color: rgb(10,10,10);
        position: fixed;
        border-radius: 50%;
        right: 0;
        width: 40px;
        height: 40px;
        display: flex;
        text-align: center; 
        font-size: 1.8rem;
        cursor: pointer;
        z-index: 4;
    }

    .fa-times {
        color: #fff;
        font-size: 2rem;
    }
}